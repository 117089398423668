import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Popup from "./components/Popup";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  position: relative;
  color: #ffffff;
  background: linear-gradient(
      to right,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%
    )
    right;
  padding: 5px 10px;
  background-size: 200%;
  text-align: center;
  transition: background 0.3s ease-in;
  width: 200px;
  border: none;

  &:hover {
    background-position: left;
    color: #ffffff;
    outline: none;
    text-decoration: none;
  }

  &:focus {
    background-position: right;
    color: #ffffff;
    outline: none;
    text-decoration: none;
  }

  &:before {
    content: "";
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    border-left: 2px #ffffff solid;
    border-top: 2px #ffffff solid;
    border-bottom: 2px #ffffff solid;
  }

  &:after {
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 5px;
    border-right: 2px #ffffff solid;
    border-top: 2px #ffffff solid;
    border-bottom: 2px #ffffff solid;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [popup, setPopup] = useState(false);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 3) {
      newMintAmount = 3;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <div className='bee-page-container'>
      <div className='bee-row bee-row-1'>
        <div className='bee-row-content'>
          <div className='bee-col bee-col-1 bee-col-w3'>
            <div className='bee-block bee-block-1 bee-spacer'>
              <div className='spacer' style={{ height: "1px" }}></div>
            </div>
          </div>
          <div className='bee-col bee-col-2 bee-col-w6'>
            <div className='bee-block bee-block-1 bee-spacer'>
              <div className='spacer' style={{ height: "5px" }}></div>
            </div>
            {/* <!-- 
<span style="float: right;">
<a href="https://moonapelab.io/">
<img style="width: 250px; margin-bottom: -400px;" alt="Moon Ape Lab" src="images/MOON-APE-LOGO-WHITE.png" title="Moon Ape Lab" /></a>
</span> --> */}
            <div className='bee-block bee-block-2 bee-social'>
              <div className='content'></div>
            </div>
            {/* <!-- <div className="hide-mobile"> --> */}
            <div id='wrapper'>
              <div id='first'>
                <img
                  alt=''
                  className='bee-center bee-autowidth'
                  src='config/images/Kick-big-new-cropped.gif'
                  style={{ maxWidth: "100vw", marginTop: "50px" }}
                />
                {/* <!-- <div className="bee-block bee-block-3 bee-image">
<img alt="" className="bee-center bee-fixedwidth" src="images/MBM.png" style="max-width:324px; margin-top: 50px;"/>
</div> --> */}
              </div>
              <div id='second'>
                <div className='bee-block bee-block-4 bee-image'>
                  <a href='https://moonapelab.io/'>
                    <img
                      style={{ width: "250px" }}
                      alt='Moon Ape Lab'
                      src='config/images/MOON-APE-LOGO-WHITE.png'
                      title='Moon Ape Lab'
                    />
                  </a>
                  {/* <!-- <img alt="" className="bee-center bee-autowidth" src="images/Kick-big-new.gif" style="max-width:720px;"/> --> */}
                </div>
              </div>
              {/* <!-- </div> --> */}
              <div className='bee-block bee-block-5 bee-button'>
                <a
                  className='bee-button-content malbtn'
                  onClick={(e) => {
                    e.preventDefault();
                    setPopup(true);
                  }}
                >
                  <span
                    dir='ltr'
                    style={{
                      wordBreak: "break-word",
                      fontSize: "24px",
                      lineHeight: "200%",
                    }}
                  >
                    MINT NOW
                  </span>
                </a>
              </div>
            </div>
            {/* <!--
<div className="show-mobile">
<div className="bee-block bee-block-3 bee-image" style={{marginBottom: "-120px"}}>
<img alt="" className="bee-center bee-fixedwidth" src="images/MBM.png" style={{maxWidth: "324px"}} />
</div>
<div className="bee-block bee-block-4 bee-image" style={{marginBottom: "-170px"}}>
<img alt="" className="bee-center bee-autowidth" src="images/Kick-big.gif" style={{maxWidth: "720px"}}/>
</div>
<div className="bee-block bee-block-5 bee-button">
<a className="bee-button-content malbtn" href="https://button.io">
<span dir="ltr" style={{wordBreak: "break-word", fontSize: "24px", lineHeight: "200%"}}>
         MINT NOW
        </span></a>
</div>
</div> --> */}
            <div className='bee-block bee-block-6 bee-spacer'>
              <div className='spacer' style={{ height: "30px" }}></div>
            </div>
            <div className='bee-block bee-block-8 bee-spacer'>
              <div className='spacer' style={{ height: "30px" }}></div>
            </div>
            <div className='bee-block bee-block-9 bee-social'>
              <div className='content'>
                <span className='icon' style={{ padding: "0 5px 0 5px" }}>
                  <a href='https://discord.gg/moonapelab'>
                    <img
                      alt='Discord'
                      src='config/images/1_10.png'
                      title='Discord'
                    />
                  </a>
                </span>
                <span className='icon' style={{ padding: "0 5px 0 5px" }}>
                  <a href='https://opensea.io/collection/mal-genesis'>
                    <img
                      alt='Opensea'
                      src='config/images/3_8.png'
                      title='Opensea'
                    />
                  </a>
                </span>
                <span className='icon' style={{ padding: "0 5px 0 5px" }}>
                  <a href='https://twitter.com/MoonApeLab'>
                    <img
                      alt='Twitter'
                      src='config/images/2_7.png'
                      title='Twitter'
                    />
                  </a>
                </span>
              </div>
            </div>
            <div className='bee-block bee-block-10 bee-spacer'>
              <div className='spacer' style={{ height: "20px" }}></div>
            </div>
          </div>
          <div className='bee-col bee-col-3 bee-col-w3'>
            <div className='bee-block bee-block-1 bee-spacer'>
              <div className='spacer' style={{ height: "1px" }}></div>
            </div>
          </div>
        </div>
      </div>

      <Popup trigger={popup} setTrigger={setPopup}>
        <s.TextTitle
          style={{
            textAlign: "center",
            fontSize: 50,
            fontWeight: "bold",
            color: "var(--accent-text)",
          }}
        >
          {data.totalSupply} / {CONFIG.MAX_SUPPLY}
        </s.TextTitle>
        <s.TextDescription
          style={{
            textAlign: "center",
            color: "var(--primary-text)",
          }}
        >
          <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
            {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
          </StyledLink>
        </s.TextDescription>
        <s.SpacerSmall />
        {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
          <>
            <s.TextTitle
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              The sale has ended.
            </s.TextTitle>
            <s.TextDescription
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              You can still find {CONFIG.NFT_NAME} on
            </s.TextDescription>
            <s.SpacerSmall />
            <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
              {CONFIG.MARKETPLACE}
            </StyledLink>
          </>
        ) : (
          <>
            <s.TextTitle
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
              {CONFIG.NETWORK.SYMBOL}.
            </s.TextTitle>
            <s.SpacerXSmall />
            <s.TextDescription
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              Excluding gas fees.
            </s.TextDescription>
            <s.SpacerSmall />
            {blockchain.account === "" || blockchain.smartContract === null ? (
              <s.Container ai={"center"} jc={"center"}>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  Connect to the {CONFIG.NETWORK.NAME} network
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledButton
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(connect());
                    getData();
                  }}
                >
                  CONNECT
                </StyledButton>
                {blockchain.errorMsg !== "" ? (
                  <>
                    <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {blockchain.errorMsg}
                    </s.TextDescription>
                  </>
                ) : null}
              </s.Container>
            ) : (
              <>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  {feedback}
                </s.TextDescription>
                <s.SpacerMedium />
                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                  <StyledRoundButton
                    style={{ lineHeight: 0.4 }}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      decrementMintAmount();
                    }}
                  >
                    -
                  </StyledRoundButton>
                  <s.SpacerMedium />
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                    }}
                  >
                    {mintAmount}
                  </s.TextDescription>
                  <s.SpacerMedium />
                  <StyledRoundButton
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      incrementMintAmount();
                    }}
                  >
                    +
                  </StyledRoundButton>
                </s.Container>
                <s.SpacerSmall />
                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                  <StyledButton
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      claimNFTs();
                      getData();
                    }}
                  >
                    {claimingNft ? "BUSY" : "BUY"}
                  </StyledButton>
                </s.Container>
              </>
            )}
          </>
        )}
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully mint your NFT. We recommend that you don't lower the
            gas limit.
          </s.TextDescription>
        </s.Container>
      </Popup>
    </div>
  );
}

export default App;
